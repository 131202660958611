import { useState } from 'react';

import { Close, Menu } from '@mui/icons-material';
import { Box, Drawer, IconButton } from '@mui/material';

import { Colors } from './theme';

const HamburgerMenu = ({ children }: { children: JSX.Element }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)}>
        <Menu />
      </IconButton>

      <Drawer
        anchor={'left'}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        color={Colors.primary.main}
        PaperProps={{
          elevation: 0
        }}>
        <Box
          sx={{
            width: '200',
            height: '100%',
            background:
              'linear-gradient(128deg, rgba(55, 140, 232, 0.50) 1.07%, rgba(255, 255, 255, 0.50) 99.98%)'
          }}
          role="presentation"
          onClick={() => setIsOpen(false)}
          onKeyDown={() => setIsOpen(false)}>
          <Box display="flex" justifyContent="flex-end" p="0.75rem 1rem">
            <IconButton
              onClick={() => setIsOpen(false)}
              color="inherit"
              sx={{ marginLeft: 'auto' }}>
              <Close />
            </IconButton>
          </Box>

          {children}
        </Box>
      </Drawer>
    </>
  );
};
export default HamburgerMenu;
