import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Container, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import ataasaImage from '../../../../assets/images/Ataasa_complete.svg';

const SectionOne: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Container
      component="section" // Add semantic section
      maxWidth={false}
      sx={{
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: -1
        }
      }}>
      <Grid
        container
        spacing={2}
        rowGap={isLargeScreen ? undefined : 4}
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: '7rem 0',
          width: '100%',
          margin: 'auto'
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto'
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}>
            <Box
              sx={{
                width: '16rem',
                height: '16rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <img
                src={ataasaImage}
                alt="Logo of Ataasa, symbolizing commitment and expertise in water solutions"
                width={'100%'}
              />
            </Box>
            <Typography
              component="h2" // Use h2 for subheading
              fontFamily={'Nunito Sans'}
              fontSize={isLargeScreen ? '3rem' : '2.75rem'}
              fontWeight={900}
              sx={{
                color: Colors.primary.text,
                padding: '4rem 0'
              }}>
              NUESTRA HISTORIA Y COMPROMISO
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Box sx={{ width: '75%' }}>
            <Typography
              component="h3" // Use h3 for section heading
              fontFamily={'Nunito Sans'}
              fontSize={'1.5rem'}
              fontWeight={900}
              lineHeight={'2.5rem'}
              sx={{
                color: Colors.primary.text,
                textAlign: isLargeScreen ? undefined : 'center'
              }}>
              SOBRE NOSOTROS
            </Typography>
            <br />
            <Typography
              fontFamily={'Nunito Sans'}
              fontSize={'1.5rem'}
              fontWeight={400}
              lineHeight={'2.5rem'}
              sx={{
                color: Colors.primary.text,
                textAlign: isLargeScreen ? undefined : 'center'
              }}>
              Desde nuestros inicios, hemos tenido una visión clara: ofrecer
              soluciones integrales en el ámbito de las albercas y tratamientos
              de agua.
            </Typography>
            <br />
            <Typography
              fontFamily={'Nunito Sans'}
              fontSize={'1.5rem'}
              fontWeight={400}
              lineHeight={'2.5rem'}
              sx={{
                color: Colors.primary.text,
                textAlign: isLargeScreen ? undefined : 'center'
              }}>
              Con años de experiencia en el mercado de Acapulco y el Estado de
              Guerrero, hemos consolidado nuestra posición como líderes en el
              diseño, construcción, instalación y mantenimiento de albercas.
            </Typography>
            <br />
            <Typography
              fontFamily={'Nunito Sans'}
              fontSize={'1.5rem'}
              fontWeight={400}
              lineHeight={'2.5rem'}
              sx={{
                color: Colors.primary.text,
                textAlign: isLargeScreen ? undefined : 'center'
              }}>
              Nuestra pasión se refleja en cada proyecto que emprendemos,
              garantizando la satisfacción total de nuestros clientes.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default SectionOne;
