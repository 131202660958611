import { ReactComponent as LogoAta } from 'assets/images/ATAASA_Logo.svg';
import { ReactComponent as Discord } from 'assets/images/home/footer/discord.svg';
import { ReactComponent as Facebook } from 'assets/images/home/footer/facebook.svg';
import { ReactComponent as Instagram } from 'assets/images/home/footer/instagram.svg';
import { ReactComponent as Tiktok } from 'assets/images/home/footer/tiktok.svg';
import { ReactComponent as X } from 'assets/images/home/footer/x.svg';
import { ReactComponent as Youtube } from 'assets/images/home/footer/youtube.svg';
import dayjs from 'dayjs';

import { Box, Container, IconButton, Typography } from '@mui/material';

import { Colors, evo2FontFamily } from 'design/theme';

const Footer = () => {
  return (
    <Box sx={{ marginTop: '2rem' }}>
      <Box
        sx={{
          backgroundColor: Colors.primary.main
        }}>
        <Container sx={{ padding: '1rem 0' }}>
          <Box
            maxHeight="6.5625rem"
            display="flex"
            sx={{ justifyContent: { xs: 'center', sm: 'space-between' } }}
            alignItems="center">
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <LogoAta />
            </Box>
            <Box>
              <Typography
                color={Colors.primary.light}
                fontFamily={evo2FontFamily}
                fontWeight={500}
                sx={{ textAlign: { xs: 'center', sm: 'start' } }}>
                {"Let's keep the conversation"}
              </Typography>
              <Box display="flex" mt="1.5rem" gap=".5rem">
                <IconButton
                  sx={{
                    backgroundColor: Colors.primary.main,
                    color: Colors.white
                  }}>
                  <Instagram />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: Colors.primary.main,
                    color: Colors.white
                  }}>
                  <Tiktok />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: Colors.primary.main,
                    color: Colors.white
                  }}>
                  <Discord />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: Colors.primary.main,
                    color: Colors.white
                  }}>
                  <Youtube />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: Colors.primary.main,
                    color: Colors.white
                  }}>
                  <X />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: Colors.primary.main,
                    color: Colors.white
                  }}>
                  <Facebook />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.black,
          padding: '2rem 0'
        }}>
        <Container>
          <Typography
            color="whitesmoke"
            fontFamily={evo2FontFamily}
            fontSize="0.875rem"
            fontWeight={500}>
            {dayjs().format('YYYY')} Albercas y Tratamientos de Agua Acapulco
            S.A. de C.V.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};
export default Footer;
