import { ReactComponent as Cdmx } from 'assets/images/home/CDMX.svg';
import { ReactComponent as Guerrero } from 'assets/images/home/Guerrero.svg';
import { ReactComponent as Morelos } from 'assets/images/home/Morelos.svg';
import { ReactComponent as Puebla } from 'assets/images/home/Puebla.svg';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Container, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

const SectionThree = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Container
      maxWidth={false}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: isLargeScreen ? '3rem' : '20rem',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          zIndex: -1
        }
      }}>
      <Grid
        container
        spacing={isLargeScreen ? 6 : 2}
        sx={{
          width: isLargeScreen ? '85%' : '100%',
          marginTop: '1rem'
        }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}>
          <Box sx={{ width: '80%' }}>
            <Typography
              fontFamily={'Nunito Sans'}
              fontSize={'1.25rem'}
              fontWeight={800}
              lineHeight={'2.5rem'}
              sx={{
                color: Colors.primary.text,
                textAlign: isLargeScreen ? undefined : 'center',
                paddingBottom: '1rem'
              }}>
              MÁS ALLÁ DE LAS ALBERCAS
            </Typography>
            <Typography
              fontFamily={'Nunito Sans'}
              fontSize={'1.25rem'}
              fontWeight={500}
              lineHeight={'2.5rem'}
              sx={{
                color: Colors.primary.text,
                textAlign: isLargeScreen ? undefined : 'center'
              }}>
              Nuestra división de Tratamiento de Agua se enorgullece de ofreccer
              soluciones completas para la purificación del agua.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}>
          <Box sx={{ width: '80%' }}>
            <Typography
              fontFamily={'Nunito Sans'}
              fontSize={'1.25rem'}
              fontWeight={500}
              lineHeight={'2.5rem'}
              sx={{
                paddingTop: isLargeScreen ? '3.25rem' : undefined,
                color: Colors.primary.text,
                textAlign: isLargeScreen ? undefined : 'center'
              }}>
              Ya sea para uso residencial or comercial, brindamos asesoría
              especializada, venta e instalación de plantas purificadoras de
              agua.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}>
          <Box sx={{ width: isLargeScreen ? '60%' : '80%' }}>
            <Typography
              fontFamily={'Nunito Sans'}
              fontSize={'1.5rem'}
              fontWeight={400}
              lineHeight={'2.5rem'}
              sx={{
                paddingTop: isLargeScreen ? '3.25rem' : undefined,
                color: Colors.primary.main,
                textAlign: 'center'
              }}>
              Nuestro alcance no se limita solo a Acapulco; damos servicio en
              diversas regiones como:
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              margin: '0 1rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: isLargeScreen ? 'flex-end' : 'center',
              alignItems: isLargeScreen ? 'flex-end' : 'center',
              height: '100%'
            }}>
            <Guerrero />
            <Typography
              fontWeight={500}
              fontSize={'1.5rem'}
              lineHeight={'2.5rem'}
              sx={{ width: '100%', textAlign: 'right', marginRight: '20%' }}>
              Guerrero
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              margin: '0 1rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: isLargeScreen ? 'flex-end' : 'center',
              alignItems: isLargeScreen ? 'flex-end' : 'center',
              height: '100%'
            }}>
            <Morelos />
            <Typography
              fontWeight={500}
              fontSize={'1.5rem'}
              lineHeight={'2.5rem'}
              sx={{ width: '100%', textAlign: 'right', marginRight: '20%' }}>
              Morelos
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              margin: '0 1rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: isLargeScreen ? 'flex-start' : 'center',
              alignItems: isLargeScreen ? 'flex-start' : 'center',
              height: '100%'
            }}>
            <Puebla />
            <Typography
              fontWeight={500}
              fontSize={'1.5rem'}
              lineHeight={'2.5rem'}
              sx={{ width: '100%', textAlign: 'left', marginLeft: '20%' }}>
              Puebla
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              margin: '0 1rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: isLargeScreen ? 'flex-start' : 'center',
              alignItems: isLargeScreen ? 'flex-start' : 'center',
              height: '100%'
            }}>
            <Cdmx />
            <Typography
              fontWeight={500}
              fontSize={'1.5rem'}
              lineHeight={'2.5rem'}
              sx={{ width: '100%', textAlign: 'left', marginLeft: '20%' }}>
              CDMX
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}>
          <Box
            sx={{
              width: isLargeScreen ? '60%' : '80%',
              marginBottom: isLargeScreen ? '1rem' : '2rem'
            }}>
            <Typography
              fontFamily={'Nunito Sans'}
              fontSize={'1.5rem'}
              fontWeight={400}
              lineHeight={'2.5rem'}
              sx={{
                paddingTop: isLargeScreen ? '3.25rem' : undefined,
                color: Colors.primary.main,
                textAlign: 'center'
              }}>
              <span style={{ fontWeight: 500, fontStyle: 'italic' }}>
                Además, ofrecemos una amplia gama de productos, desde
              </span>{' '}
              maquinaria de envasado hasta filtros de última generación.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default SectionThree;
