import backgroundImage from 'assets/images/home/STOCK.png';
import Water from 'assets/images/home/Water-hero.png';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Typography } from '@mui/material';

import HeaderContent from './HeaderContent';

const HomeHeader: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          mt: '-20rem',
          pt: '10rem',
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          maxHeight: '130rem',
          height: isLargeScreen ? '100rem' : '80rem'
        }}>
        <HeaderContent />

        <Box
          component="img"
          src={Water}
          alt="Water effect"
          sx={{
            position: 'absolute',
            top: '60rem',
            left: 0,
            width: '100%',
            opacity: 0.7,
            objectFit: 'cover',
            zIndex: 1
          }}
        />

        {/* Positioned text box */}
        <Box
          sx={{
            position: 'absolute',
            width: isLargeScreen ? '60%' : '80%',
            top: isLargeScreen ? '90rem' : undefined,
            left: isLargeScreen ? '12%' : '3rem',
            zIndex: 2
          }}>
          <Typography
            fontFamily={'Nunito Sans'}
            fontSize={'1.5rem'}
            fontWeight={400}
            lineHeight={'2rem'}
            sx={{
              color: 'white',
              textAlign: isLargeScreen ? undefined : 'center'
            }}>
            Dedicados al diseño, construcción, instalación y mantenimiento de
            albercas en{' '}
            <span style={{ fontWeight: 700, fontStyle: 'italic' }}>
              Acapulco y todo el Estado de Guerrero.
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeHeader;
