import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Container, Typography } from '@mui/material';

import { Colors } from 'design/theme';

const HeaderContent: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <>
      <Container
        component="section" // Semantic HTML for better SEO
        sx={{
          margin: 'auto',
          alignContent: isLargeScreen ? undefined : 'center',
          justifyContent: isLargeScreen ? 'center' : undefined,
          maxWidth: '100%'
        }}>
        <Box
          sx={{
            zIndex: 1,
            marginTop: isLargeScreen ? '47rem' : '30rem',
            paddingLeft: isLargeScreen ? 0 : undefined
          }}>
          <Typography
            component="h1" // Use H1 for primary heading on the page
            fontFamily={'Nunito Sans'}
            fontSize={isLargeScreen ? '5rem' : '2.5rem'}
            color={Colors.white}
            fontWeight={900}
            letterSpacing={'0.25rem'}
            sx={{
              textAlign: isLargeScreen ? undefined : 'left',
              margin: isLargeScreen ? undefined : '0.5rem',
              marginBottom: isLargeScreen ? undefined : '10rem'
            }}>
            {isLargeScreen
              ? 'INNOVANDO LA EXPERIENCIA'
              : 'INNOVANDO EXPERIENCIAS BAJO EL AGUA'}
          </Typography>
        </Box>
        {isLargeScreen && (
          <Box
            sx={{
              width: '100%',
              marginTop: '1rem',
              paddingLeft: isLargeScreen ? 0 : undefined,
              marginBottom: '10rem'
            }}>
            <Typography
              component="h2" // Use H2 for subheading
              fontFamily={'Nunito Sans'}
              fontSize={isLargeScreen ? '5rem' : '2rem'}
              fontWeight={800}
              sx={{
                color: Colors.white,
                textAlign: isLargeScreen ? undefined : 'left',
                margin: isLargeScreen ? undefined : '0.5rem',
                width: '80%'
              }}>
              BAJO EL AGUA.
            </Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default HeaderContent;
