import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';

import { Colors } from 'design/theme';

export const Contact: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: isLargeScreen ? 'column' : 'column',
        justifyContent: 'center',
        margin: 0,
        marginTop: '-1rem',
        marginBottom: '1rem',
        alignItems: 'center',
        backgroundColor: 'transparent'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          background: '#378CE8',
          width: '85%',
          minHeight: '50rem',
          borderRadius: '0.75rem',
          padding: isLargeScreen ? '5rem' : '1rem'
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            fontFamily={'Nunito Sans'}
            fontWeight={800}
            fontSize={isLargeScreen ? '3rem' : '2rem'}
            lineHeight={isLargeScreen ? '4rem' : '2.5rem'}
            sx={{
              color: Colors.white,
              textAlign: isLargeScreen ? undefined : 'center'
            }}>
            ¡CONTÁCTANOS HOY!
          </Typography>
          <Typography
            fontFamily={'Nunito Sans'}
            fontWeight={400}
            fontSize={isLargeScreen ? '2rem' : '1.5rem'}
            lineHeight={isLargeScreen ? '2rem' : '1.75rem'}
            letterSpacing={'0.08rem'}
            sx={{
              color: Colors.white,
              width: isLargeScreen ? '50%' : '100%',
              marginTop: '1rem',
              textAlign: isLargeScreen ? undefined : 'center'
            }}>
            Y da el primer paso hacia tu nueva alberca o sistema de purificación
            de agua.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: isLargeScreen ? '65%' : '90%',
            margin: 'auto',
            marginTop: isLargeScreen ? '5rem' : '1rem',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
          }}>
          <Paper
            elevation={8}
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5rem',
              backgroundColor: Colors.white
            }}>
            <Formik
              enableReinitialize
              initialValues={{ name: '', email: '', phone: '', message: '' }}
              onSubmit={(values, { setSubmitting }) => {
                axios
                  .post('https://getform.io/f/pbmqgrvb', values, {
                    headers: {
                      Accept: 'application/json'
                    }
                  })
                  .then((response: any) => {
                    console.log(response);
                    setIsSubmitted(true);
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    console.log(error);
                    setSubmitting(false);
                  });
              }}>
              {() => (
                <Form>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      width: 'auto',
                      padding: isLargeScreen ? '3rem' : '1rem',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <Grid item md={12}>
                      <Field
                        as={TextField}
                        label="Name"
                        name="name"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={7}>
                      <Field
                        as={TextField}
                        label="Email"
                        name="email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={5}>
                      <Field
                        as={TextField}
                        label="Phone Number"
                        name="phone"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Field
                        as={TextField}
                        label="Message"
                        name="message"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        multiline
                        rows={10}
                      />
                    </Grid>
                    <Grid item>
                      {isSubmitted ? (
                        <Typography
                          fontFamily={'Nunito Sans'}
                          fontWeight={600}
                          fontSize={'1.25rem'}
                          lineHeight={'1.5srem'}
                          letterSpacing={'0.08rem'}
                          sx={{
                            color: Colors.blue,
                            width: '100%',
                            marginTop: '1rem',
                            textAlign: 'center'
                          }}>
                          Gracias por ponerte en contacto con nosotros! Nos
                          comunicaremos cuanto antes.
                        </Typography>
                      ) : (
                        <Button
                          type="submit"
                          disabled={isSubmitted}
                          variant="contained"
                          sx={{
                            marginTop: '1rem',
                            background: '#001A5B',
                            fontFamily: 'Nunito Sans',
                            fontSize: '1rem',
                            fontWeight: '800',
                            lineHeight: '1.5rem',
                            width: '10rem',
                            height: '3.5rem'
                          }}>
                          ENVIAR
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
