import { ReactComponent as Logo } from 'assets/images/ATAASA_Logo.svg';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  AppBar,
  Box,
  Grid,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';

import HamburgerMenu from 'design/HamburgerMenu';
import { Colors } from 'design/theme';

import RoutesEnum from 'types/routes.enum';

const FullScreen = () => {
  const isLargeScreen = useIsLargeScreen();
  const navigate = useNavigate();

  // Adjust justifyContent based on screen size
  const justifyContentValue = isLargeScreen ? 'flex-start' : 'space-between';

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          maxWidth: '100vw',
          width: '100%',
          overflowX: 'hidden',
          backgroundColor: 'transparent',
          transition: 'background 0.3s ease-in-out',
          background:
            'linear-gradient(90deg, rgba(55, 140, 232, 0.60) 30.07%, rgba(255, 255, 255, 0.1) 100%)',
          backdropFilter: 'blur(2.5px)',
          borderBottom: `2px solid ${Colors.primary.main}`,
          left: '0'
        }}>
        <Toolbar sx={{ height: '100%' }}>
          <Grid
            container
            alignItems="center"
            justifyContent={justifyContentValue}
            sx={{ maxWidth: '100%' }}>
            {/* Hamburger Menu for small screens */}
            {!isLargeScreen ? (
              <>
                <Grid
                  item
                  sm={6}
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ maxWidth: '100%' }}>
                  <Box>
                    <HamburgerMenu>
                      <>
                        <MenuItem
                          onClick={() => {
                            navigate(RoutesEnum.HOME);
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              width: '50%',
                              height: '100%'
                            }}>
                            <Typography
                              align="center"
                              margin={'1rem'}
                              fontFamily={'Nunito Sans'}
                              fontWeight={800}
                              lineHeight={'1.5rem'}>
                              NOSOTROS
                            </Typography>
                            <Typography
                              align="center"
                              margin={'1rem'}
                              fontFamily={'Nunito Sans'}
                              fontWeight={800}
                              lineHeight={'1.5rem'}>
                              ALBERCAS
                            </Typography>
                            <Typography
                              align="center"
                              margin={'1rem'}
                              fontFamily={'Nunito Sans'}
                              fontWeight={800}
                              lineHeight={'1.5rem'}>
                              TRATAMIENTOS
                            </Typography>
                            <Typography
                              align="center"
                              margin={'1rem'}
                              fontFamily={'Nunito Sans'}
                              fontWeight={800}
                              lineHeight={'1.5rem'}>
                              PRODUCTOS
                            </Typography>
                            <Typography
                              align="center"
                              margin={'1rem'}
                              fontFamily={'Nunito Sans'}
                              fontWeight={800}
                              lineHeight={'1.5rem'}>
                              TESTIMONIOS
                            </Typography>
                            <Typography
                              align="center"
                              margin={'1rem'}
                              fontFamily={'Nunito Sans'}
                              fontWeight={800}
                              lineHeight={'1.5rem'}>
                              CONTACTO
                            </Typography>
                          </Box>
                        </MenuItem>
                      </>
                    </HamburgerMenu>
                  </Box>
                </Grid>
                <Grid item sm={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '0.5rem'
                    }}>
                    <Logo style={{ fill: 'currentcolor' }} />
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={12} md={12} lg={5}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginTop: '0.5rem'
                    }}>
                    <Logo style={{ fill: 'currentcolor' }} />
                  </Box>
                </Grid>
                <Grid item sm={3} md={2} lg={2}>
                  <Typography
                    align="center"
                    margin={'1rem'}
                    fontFamily={'Nunito Sans'}
                    fontWeight={800}
                    lineHeight={'1.5rem'}
                    sx={{
                      transition: 'color 0.3s ease, transform 0.3s ease',
                      ':hover': {
                        color: Colors.primary.dark,
                        transform: 'scale(1.05)'
                      }
                    }}>
                    NUESTRO TRABAJO
                  </Typography>
                </Grid>
                <Grid item sm={3} md={2} lg={1}>
                  <Typography
                    align="right"
                    margin={'1rem'}
                    fontFamily={'Nunito Sans'}
                    fontWeight={800}
                    lineHeight={'1.5rem'}
                    sx={{
                      transition: 'color 0.3s ease, transform 0.3s ease',
                      ':hover': {
                        color: Colors.primary.dark,
                        transform: 'scale(1.05)'
                      }
                    }}>
                    ALBERCAS
                  </Typography>
                </Grid>
                <Grid item sm={3} md={2} lg={2}>
                  <Typography
                    align="right"
                    margin={'1rem'}
                    fontFamily={'Nunito Sans'}
                    fontWeight={800}
                    lineHeight={'1.5rem'}
                    sx={{
                      transition: 'color 0.3s ease, transform 0.3s ease',
                      ':hover': {
                        color: Colors.primary.dark,
                        transform: 'scale(1.05)'
                      }
                    }}>
                    TRATAMIENTOS
                  </Typography>
                </Grid>

                <Grid item sm={6} md={2} lg={1}>
                  <Typography
                    align="right"
                    margin={'1rem'}
                    fontFamily={'Nunito Sans'}
                    fontWeight={800}
                    lineHeight={'1.5rem'}
                    sx={{
                      transition: 'color 0.3s ease, transform 0.3s ease',
                      ':hover': {
                        color: Colors.primary.dark,
                        transform: 'scale(1.05)'
                      }
                    }}>
                    CONTACTO
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          maxWidth: '100%',
          maxHeight: '100vh',
          boxSizing: 'border-box'
        }}>
        <Outlet />
      </Box>
    </>
  );
};

export default FullScreen;
