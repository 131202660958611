import { Box } from '@mui/material';

import WaitScreen from './components/WaitScreen';
import Contact from './components/compatibles/Contact';
import Footer from './components/compatibles/Footer';
import { SectionEight } from './components/compatibles/SectionEight';
import SectionFive from './components/compatibles/SectionFive';
import SectionFour from './components/compatibles/SectionFour';
import { SectionSeven } from './components/compatibles/SectionSeven';
import { SectionSix } from './components/compatibles/SectionSix';
import SectionThree from './components/compatibles/SectionThree';
import SectionTwo from './components/compatibles/SectionTwo';
import HomeHeader from './components/desktop/Header/Header';
import SectionOne from './components/desktop/SectionOne';

const Home: React.FC = () => {
  return (
    <Box
      sx={{
        maxWidth: '100vw',
        width: '100%',
        overflowX: 'hidden',
        backgroundImage:
          'linear-gradient(to bottom, #20e2dd, #00ced8, #00b9cf, #00a4c3, #0090b5, #3b91b8, #5693b9, #6a94b9, #97accb, #bec6dc, #e0e1ed, #ffffff)'
      }}>
      <HomeHeader />
      <Box
        sx={{
          position: 'relative',
          overflowY: 'hidden',
          zIndex: 3,
          background: 'transparent'
        }}>
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <SectionSix />
        <SectionSeven />
        <Box
          sx={{
            position: 'relative',
            overflowY: 'hidden',
            zIndex: 3,
            marginTop: '-1rem',
            backgroundImage:
              'linear-gradient(to bottom,  #001A5B, #3b91b8,  #5693b9, #3b91b8, #5693b9, #3b91b8, #5693b9, #6a94b9, #97accb, #bec6dc, #e0e1ed, #ffffff)'
          }}>
          {' '}
          <SectionEight />
          <Contact />
        </Box>

        <Footer />
      </Box>

      <WaitScreen />
    </Box>
  );
};

export default Home;
