import Demo1 from 'assets/images/Demo1.png';
import Demo2 from 'assets/images/Demo2.png';
import Demo3 from 'assets/images/Demo3.png';
import Wave from 'assets/images/home/Wave.png';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Container, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

const SectionTwo: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Container
      maxWidth={false}
      sx={{
        paddingTop: '7rem',
        marginBottom: '0',
        marginTop: '0rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        background:
          'linear-gradient(180deg,  rgba(0, 118, 159, 0.8) 6%,  rgba(255, 255, 255, 0.60) 100%)'
      }}>
      <Box
        sx={{
          margin: 'auto',
          marginBottom: '0rem',
          width: isLargeScreen ? '60%' : '85%',
          backgroundColor: Colors.white
        }}>
        <Box
          component="img"
          src={Wave}
          alt="Water effect"
          sx={{
            maxHeight: '30rem',
            maxWidth: isLargeScreen ? '30rem' : undefined,
            opacity: 0.7,
            marginTop: '-10rem',
            marginLeft: isLargeScreen ? '60%' : '20%'
          }}
        />
        <Box
          sx={{
            width: '75%',
            margin: isLargeScreen ? '-2rem 4rem' : 'auto'
          }}>
          <Typography
            fontFamily={'Nunito Sans'}
            fontSize={'2rem'}
            fontWeight={800}
            lineHeight={'2.5rem'}
            sx={{
              color: Colors.primary.text,
              textAlign: isLargeScreen ? undefined : 'center'
            }}>
            COMPROMISO CON LA CALIDAD
          </Typography>
          <Box
            sx={{
              width: isLargeScreen ? '80%' : '100%',
              marginTop: '3rem'
            }}>
            <Typography
              fontFamily={'Nunito Sans'}
              fontSize={isLargeScreen ? '1.5rem' : '1.25rem'}
              fontWeight={400}
              lineHeight={'2.5rem'}
              sx={{
                color: Colors.primary.text,
                textAlign: isLargeScreen ? undefined : 'left'
              }}>
              No solo nos dedicamos a la construcción y mantenimiento de
              albercas, sino que también somos proveedores de equipos y
              accesorios de la más alta calidad.
            </Typography>
            <br />
            <Typography
              fontFamily={'Nunito Sans'}
              fontSize={isLargeScreen ? '1.5rem' : '1.25rem'}
              fontWeight={400}
              lineHeight={'2.5rem'}
              sx={{
                color: Colors.primary.text,
                textAlign: isLargeScreen ? undefined : 'left'
              }}>
              Desde mosaicos venecianos hasta sistemas de iluminación, cada
              producto que ofrecemos cumple con los más altos estándares del
              mercado. Además,{' '}
              <span style={{ fontWeight: 700, fontStyle: 'italic' }}>
                entenemos la importancia de un agua limpia y pura,{' '}
              </span>
              por lo que surtimos todos los químicos necesarios para la
              purificación y tratamientos de agua para consumo humano y
              albercas.
            </Typography>
          </Box>
        </Box>
        <Grid
          container
          gap={2}
          sx={{
            margin: '2rem auto',
            marginTop: '5rem',
            marginLeft: isLargeScreen ? '-15%' : '-5%',
            width: isLargeScreen ? '125%' : '110%',
            alignContent: 'center',
            justifyContent: 'space-around'
          }}>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}>
            <img src={Demo1} alt="demo1" width={'110%'} />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}>
            <img src={Demo2} alt="demo2" width={'110%'} />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}>
            <img src={Demo3} alt="demo3" width={'110%'} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default SectionTwo;
