import { ReactComponent as Build } from 'assets/images/home/BuildPools.svg';
import { ReactComponent as Design } from 'assets/images/home/DesignPools.svg';
import { ReactComponent as Installation } from 'assets/images/home/InstallPools.svg';
import { ReactComponent as Mantenance } from 'assets/images/home/MantPools.svg';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Typography } from '@mui/material';

import { Colors } from 'design/theme';

const SectionFive: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '30rem',
        display: 'flex',
        flexDirection: isLargeScreen ? 'column' : 'column',
        justifyContent: 'center',
        margin: 0,
        marginTop: '-2rem',
        marginBottom: '1rem',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: -1
        }
      }}>
      <Box>
        <Typography
          variant="h2"
          fontFamily={'Nunito Sans'}
          fontWeight={800}
          fontSize={isLargeScreen ? '3.25rem' : '2.5rem'}
          letterSpacing={'0.25rem'}
          lineHeight={isLargeScreen ? '4.5rem' : '3rem'}
          sx={{
            background: 'transparent',
            color: Colors.primary.text,
            textAlign: isLargeScreen ? undefined : 'center',
            margin: isLargeScreen ? undefined : '1rem'
          }}>
          SERVICIOS DE ALBERCAS
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '3rem',
          minWidth: '100%',
          border: '2px solid rgba(32, 226, 221, 0.6)',
          boxShadow: '2px 2px 2px 5px rgba(32, 226, 221, 0.2)',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: isLargeScreen ? 'row' : 'column',
          padding: '1rem'
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '3rem'
          }}>
          <Typography
            fontFamily={'Nunito Sans'}
            fontWeight={600}
            fontSize={'1.25rem'}
            letterSpacing={'0.125rem'}
            lineHeight={'2rem'}
            sx={{ width: '100%', textAlign: 'center' }}>
            DISEÑO
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Design />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '3rem'
          }}>
          <Typography
            fontFamily={'Nunito Sans'}
            fontWeight={600}
            fontSize={'1.25rem'}
            letterSpacing={'0.125rem'}
            lineHeight={'2rem'}
            sx={{ width: '100%', textAlign: 'center' }}>
            CONSTRUCCIÓN
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Build />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '3rem'
          }}>
          <Typography
            fontFamily={'Nunito Sans'}
            fontWeight={600}
            fontSize={'1.25rem'}
            letterSpacing={'0.125rem'}
            lineHeight={'2rem'}
            sx={{ width: '50%', textAlign: 'center' }}>
            INSTALACIÓN DE EQUIPOS
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Installation />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '3rem'
          }}>
          <Typography
            fontFamily={'Nunito Sans'}
            fontWeight={600}
            fontSize={'1.25rem'}
            letterSpacing={'0.125rem'}
            lineHeight={'2rem'}
            sx={{ width: '100%', textAlign: 'center' }}>
            MANTENIMIENTO
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Mantenance />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default SectionFive;
