import { ReactComponent as AtaasaTeam } from 'assets/images/home/Ataasa_Team.svg';
import React from 'react';

import { Box, Container, Typography } from '@mui/material';

import { Colors } from '../../../../design/theme';
import useIsLargeScreen from '../../../../hooks/useIsLargeScreen';

const SectionFour: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Container
      maxWidth={false}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: isLargeScreen ? 'row' : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: isLargeScreen ? '-3rem' : '-20rem',
        marginBottom: '2rem',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: -1
        }
      }}>
      <Box
        sx={{
          width: '80%',
          marginLeft: '-1rem',
          minHeight: '100vh',
          marginTop: isLargeScreen ? '-10rem' : '-18rem'
        }}>
        <AtaasaTeam style={{ width: '100%' }} />
      </Box>
      <Box
        sx={{
          width: isLargeScreen ? '100%' : '80%',
          minHeight: '50vh',
          gap: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          marginTop: isLargeScreen ? undefined : '-16rem',
          marginBottom: isLargeScreen ? undefined : '2rem',
          marginLeft: isLargeScreen ? '-15rem' : undefined,
          marginRight: isLargeScreen ? '5rem' : undefined
        }}>
        <Typography
          fontWeight={800}
          fontSize={'1.5rem'}
          lineHeight={'2.5rem'}
          sx={{ textAlign: 'right', color: Colors.primary.text }}>
          {' '}
          UN EQUIPO DEDICADO
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={'1.25rem'}
          lineHeight={'2.5rem'}
          sx={{ textAlign: 'right', color: Colors.primary.text }}>
          Nuestro mayor activo es nuestro equipo de profesionales altamente
          capacitados y dedicados. <br />
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={'1.25rem'}
          lineHeight={'2.5rem'}
          sx={{ textAlign: 'right', color: Colors.primary.text }}>
          {' '}
          Cada miembro de nuestro equipo comparte la visión de ofrecer
          soluciones excepcionales y un servicio al cliente inigualable.{''}
          <span style={{ fontWeight: '800' }}>
            Gracias a su experiencia y compromiso, hemos logrado construir
            relaciones duraderas con nuestros clientes y proveedores.
          </span>
        </Typography>
      </Box>
    </Container>
  );
};
export default SectionFour;
