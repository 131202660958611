import { ReactComponent as FirstProject } from 'assets/images/home/AboutUs_img_1.svg';
import { ReactComponent as SecondProject } from 'assets/images/home/AboutUs_img_2.svg';
import { ReactComponent as ThirdProject } from 'assets/images/home/AboutUs_img_3.svg';
import { ReactComponent as FourthProject } from 'assets/images/home/AboutUs_img_4.svg';
import { ReactComponent as BackArrow } from 'assets/images/home/BackArrow.svg';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Button, Container, Typography } from '@mui/material';

const AboutUs: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box
      mt="10rem"
      sx={{
        maxWidth: '100vw',
        width: '100%'
      }}>
      <Box
        ml="4rem"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start'
        }}>
        <BackArrow width={'15px'} />
      </Box>
      <Container>
        <Box
          mt={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography
            fontWeight={800}
            fontSize={'5.5rem'}
            sx={{ color: '#378CE8' }}>
            NUESTRO TRABAJO
          </Typography>
          <Typography
            fontWeight={400}
            fontSize={'1rem'}
            sx={{ color: '#001A5B' }}
            textAlign={'center'}
            maxWidth={'40rem'}>
            Nuestra pasión se refleja en cada proyecto que emprendemos,
            garantizando la satisfacción total de nuestros clientes
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              zIndex: 0
            }}>
            <FirstProject width={'60rem'} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: { md: 'flex-end', xs: 'center' },
              gap: '2rem',
              position: 'relative',
              bottom: isLargeScreen ? '10rem' : '20rem'
            }}>
            <Box
              sx={{
                zIndex: 1,
                padding: '0rem 2rem 2.5rem 2rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                border: '3px solid #20E2DD',
                height: '25rem',
                width: '20rem'
              }}>
              <Typography
                fontWeight={800}
                fontSize={'2rem'}
                sx={{ color: '#001A5B', paddingBottom: '2rem' }}>
                Nombre de proyecto.
              </Typography>
              <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                Ubicación
              </Typography>
              <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                Información
              </Typography>
              <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                Fechas
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end'
              }}>
              <Typography
                fontWeight={400}
                fontSize={'1rem'}
                sx={{
                  color: '#001A5B',
                  textAlign: 'start',
                  maxWidth: '30rem'
                }}>
                Descripción de proyecto (algo breve): consectetur adipiscing
                elit. Etiam hendrerit tristique metus. Vestibulum molestie velit
                metus. Maecenas euismod tristique leo, vel semper ante ornare
                eget.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              zIndex: 0
            }}>
            <SecondProject width={'60rem'} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap-reverse',
              justifyContent: { md: 'flex-end', xs: 'center' },
              gap: '2rem',
              position: 'relative',
              bottom: isLargeScreen ? '6rem' : '20rem'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end'
              }}>
              <Typography
                fontWeight={400}
                fontSize={'1rem'}
                sx={{
                  color: '#001A5B',
                  textAlign: isLargeScreen ? 'end' : 'start',
                  maxWidth: isLargeScreen ? '20rem' : '30rem'
                }}>
                Descripción de proyecto (algo breve): consectetur adipiscing
                elit. Etiam hendrerit tristique metus. Vestibulum molestie velit
                metus. Maecenas euismod tristique leo, vel semper ante ornare
                eget.
              </Typography>
            </Box>
            <Box
              sx={{
                zIndex: 1,
                padding: '0rem 2rem 2.5rem 2rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                border: '3px solid #20E2DD',
                height: '25rem',
                width: '20rem'
              }}>
              <Typography
                fontWeight={800}
                fontSize={'2rem'}
                sx={{ color: '#001A5B', paddingBottom: '2rem' }}>
                Nombre de proyecto.
              </Typography>
              <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                Ubicación
              </Typography>
              <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                Información
              </Typography>
              <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                Fechas
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              zIndex: 0,
              padding: isLargeScreen ? '0 0 0 1.5rem' : '0rem'
            }}>
            <ThirdProject width={'60rem'} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '2rem',
              position: 'relative',
              bottom: isLargeScreen ? '5rem' : '20rem',
              paddingLeft: isLargeScreen ? '7.5rem' : undefined
            }}>
            <Box
              sx={{
                zIndex: 1,
                padding: '0rem 2rem 2.5rem 2rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                border: '3px solid #20E2DD',
                height: '25rem',
                width: isLargeScreen ? '22.5rem' : '20rem'
              }}>
              <Typography
                fontWeight={800}
                fontSize={'2rem'}
                sx={{ color: '#001A5B', paddingBottom: '2rem' }}>
                Nombre de proyecto.
              </Typography>
              <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                Ubicación
              </Typography>
              <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                Información
              </Typography>
              <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                Fechas
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end'
              }}>
              <Typography
                fontWeight={400}
                fontSize={'1rem'}
                sx={{
                  color: '#001A5B',
                  textAlign: 'start',
                  maxWidth: isLargeScreen ? '20rem' : '30rem'
                }}>
                Descripción de proyecto (algo breve): consectetur adipiscing
                elit. Etiam hendrerit tristique metus. Vestibulum molestie velit
                metus. Maecenas euismod tristique leo, vel semper ante ornare
                eget.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              zIndex: 0,
              padding: isLargeScreen ? '0 0 0 1.5rem' : undefined
            }}>
            <FourthProject width={'60rem'} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'flex-end',
              gap: '2rem',
              position: 'relative',
              bottom: isLargeScreen ? '5rem' : '20rem',
              paddingLeft: isLargeScreen ? '7.5rem' : undefined
            }}>
            <Box
              sx={{
                zIndex: 1,
                display: 'flex',
                justifyContent: isLargeScreen ? 'center' : 'flex-end',
                border: '3px solid #20E2DD',
                alignItems: isLargeScreen ? 'center' : undefined,
                alignSelf: isLargeScreen ? undefined : 'center',
                flexDirection: isLargeScreen ? 'row' : 'column',
                height: isLargeScreen ? undefined : '25rem',
                width: isLargeScreen ? '30rem' : '20rem',
                padding: isLargeScreen
                  ? '2rem 0 2rem 0'
                  : '0rem 2rem 2.5rem 2rem'
              }}>
              <Box
                sx={{
                  padding: isLargeScreen ? '0 2rem 0 2rem' : '0 0 2rem 0'
                }}>
                <Typography
                  fontWeight={800}
                  fontSize={'2rem'}
                  sx={{ color: '#001A5B' }}>
                  Nombre de proyecto.
                </Typography>
              </Box>
              <Box
                sx={{ padding: isLargeScreen ? '0 4rem 0 4rem' : undefined }}>
                <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                  Ubicación
                </Typography>
                <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                  Información
                </Typography>
                <Typography sx={{ color: '#378CE8', paddingBottom: '1rem' }}>
                  Fechas
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end'
              }}>
              <Typography
                fontWeight={400}
                fontSize={'1rem'}
                sx={{
                  color: '#001A5B',
                  textAlign: 'start',
                  maxWidth: isLargeScreen ? '20rem' : '30rem'
                }}>
                Descripción de proyecto (algo breve): consectetur adipiscing
                elit. Etiam hendrerit tristique metus. Vestibulum molestie velit
                metus. Maecenas euismod tristique leo, vel semper ante ornare
                eget.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          backgroundColor: 'rgba(55, 140, 232, 0.12)',
          width: '100%',
          height: '40rem',
          zIndex: -1,
          position: 'absolute',
          top: '50rem'
        }}></Box>
      <Box
        sx={{
          backgroundColor: 'rgba(55, 140, 232, 0.12)',
          width: '100%',
          height: '140rem',
          zIndex: -1,
          position: 'absolute',
          top: '165rem',
          left: isLargeScreen ? '5rem' : undefined
        }}></Box>
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'white',
          boxShadow: '0 -10px 50px -1px rgba(0, 0, 0, 0.3);'
        }}>
        <Container>
          <Box py={'10rem'}>
            <Typography
              fontWeight={800}
              fontSize={isLargeScreen ? '4rem' : '2rem'}
              sx={{ color: '#378CE8' }}>
              ¡CONTÁCTANOS HOY!
            </Typography>
            <Typography
              fontWeight={600}
              fontSize={'1.5rem'}
              sx={{ color: '#378CE8' }}>
              ¿Te gusta lo que ves? Contáctanos y da el primer paso hacia tu
              nueva alberca o sistema de purificación de agua.
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#001A5B', marginTop: '5rem' }}>
              <Typography sx={{ padding: '0.5rem 0.25rem' }}>
                CONTACTAR AHORA
              </Typography>
            </Button>

            <Box mt={'10rem'} color={'#001A5B'}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '3rem',
                  mb: '2rem'
                }}>
                <Typography>Dirección</Typography>
                <Typography>ventas1@ataasa.com.mx</Typography>
                <Typography>Tel 00 000 00</Typography>
              </Box>
              <hr color="#001A5B" />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '1rem',
                  height: '1.5rem',
                  mt: '2rem'
                }}>
                <Box
                  sx={{
                    borderRadius: '50%',
                    width: '1.5rem',
                    border: '1px solid #001A5B'
                  }}></Box>
                <Box
                  sx={{
                    borderRadius: '50%',
                    width: '1.5rem',
                    border: '1px solid #001A5B'
                  }}></Box>
                <Box
                  sx={{
                    borderRadius: '50%',
                    width: '1.5rem',
                    border: '1px solid #001A5B'
                  }}></Box>
              </Box>
              <Typography mt="2rem" align="center">
                © Copyright Albercas y Tratamientos de Agua Acapulco, Acapulco,
                Guerrero
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUs;
