import AboutUs from 'pages/AboutUs/AboutUs';
import Home from 'pages/Home/Home';
import { Navigate, Route, Routes as RoutesFromRouter } from 'react-router-dom';

import FullScreen from 'design/Layouts/FullScreen';

import RoutesEnum from 'types/routes.enum';

const Routes = () => (
  <RoutesFromRouter>
    <Route element={<FullScreen />}>
      <Route path={RoutesEnum.HOME} element={<Home />} />
      <Route path={RoutesEnum.US} element={<AboutUs />} />
    </Route>

    <Route path="*" element={<Navigate to="/" />} />
  </RoutesFromRouter>
);
export default Routes;
