import { ReactComponent as Guerrero } from 'assets/images/home/GuerreroMap.svg';
import { ReactComponent as WaterText } from 'assets/images/home/waterElement.svg';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Typography } from '@mui/material';

import { Colors } from 'design/theme';

export const SectionSeven: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '40rem',
        display: 'flex',
        flexDirection: isLargeScreen ? 'column' : 'column',
        justifyContent: 'center',
        margin: 0,
        marginTop: '-1rem',
        marginBottom: '1rem',
        alignItems: 'center',
        background: Colors.primary.text
      }}>
      <Box
        sx={{
          width: '100%',
          padding: isLargeScreen ? '5rem' : '2rem 1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography
          fontFamily={'Nunito Sans'}
          fontSize={isLargeScreen ? '3rem' : '2rem'}
          lineHeight={'4rem'}
          fontWeight={800}
          sx={{
            color: Colors.white,
            width: isLargeScreen ? '50%' : '100%',
            textAlign: 'center'
          }}>
          SOLUCIONES DE TRATAMIENTOS DE{' '}
          <span style={{ position: 'relative' }}>
            AGUA
            <Box
              sx={{
                position: 'absolute',
                left: isLargeScreen ? '10%' : '-35%',
                bottom: isLargeScreen ? '-155px' : '-165px',
                transform: 'translateX(-50%)',
                width: '100%'
              }}>
              <WaterText />
            </Box>
          </span>
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '5rem'
        }}>
        <Typography
          fontFamily={'Nunito Sans'}
          fontSize={isLargeScreen ? '1.5rem' : '1.25rem'}
          lineHeight={'2rem'}
          fontWeight={400}
          letterSpacing={'0.08rem'}
          sx={{ color: Colors.white, width: '60%', textAlign: 'center' }}>
          Especialistas en venta, asesoría e instalación de plantas
          purificadoras de agua en{' '}
          <span style={{ fontWeight: '900', fontStyle: 'italic' }}>
            Acapulco y regiones circundantes.
          </span>
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          zIndex: 10,
          marginTop: isLargeScreen ? '-1rem' : '-14rem',
          marginBottom: isLargeScreen ? '-10rem' : '-23rem'
        }}>
        <Guerrero
          style={{
            width: isLargeScreen ? '60%' : '100%',
            height: isLargeScreen ? 'auto' : undefined
          }}
        />
      </Box>
    </Box>
  );
};
