import Bomba from 'assets/images/home/equipos/BombaAlbercas.png';
import Lampara from 'assets/images/home/equipos/LamparaAlbercas.png';
import Veneciano from 'assets/images/home/equipos/VenezianoAlbercas.png';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Grid, Paper, Typography } from '@mui/material';

import { Colors } from 'design/theme';

export const SectionEight: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const items = [
    { label: 'Plantas purificadoras', image: Veneciano },
    { label: 'Maquinas de envasado', image: Bomba },
    { label: 'Filtros', image: Lampara }
  ];

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: isLargeScreen ? 'column' : 'column',
        justifyContent: 'center',
        margin: 0,
        marginTop: '-1rem',
        marginBottom: '1rem',
        alignItems: 'center',
        backgroundColor: 'transparent'
      }}>
      <Box sx={{ marginTop: isLargeScreen ? '12rem' : '8rem' }}>
        <Typography
          variant="h2"
          fontFamily={'Nunito Sans'}
          fontWeight={800}
          fontSize={isLargeScreen ? '3.25rem' : '2.5rem'}
          letterSpacing={'0.25rem'}
          lineHeight={isLargeScreen ? '4.5rem' : '3rem'}
          sx={{
            background: 'transparent',
            color: Colors.white,
            textAlign: isLargeScreen ? undefined : 'center',
            margin: isLargeScreen ? undefined : '1rem'
          }}>
          EQUIPOS DE PURIFICACIÓN DE AGUA
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: isLargeScreen ? '5rem' : '1rem',
          minWidth: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1rem',
          marginBottom: '5rem'
        }}>
        <Grid
          container
          columnGap={1}
          rowGap={isLargeScreen ? undefined : 2}
          sx={{
            width: isLargeScreen ? '80%' : '65%',
            justifyContent: 'space-between'
          }}>
          {items.map((item, index) => (
            <Grid
              item
              sm={12}
              md={3.75}
              key={index}
              sx={{ minHeight: '15rem' }}>
              <Paper
                elevation={3}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '1rem',
                  gap: '0.5rem'
                }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <img
                    src={item.image}
                    alt={item.label}
                    width={isLargeScreen ? '100%' : '75%'}
                  />
                </Box>
                <Typography
                  fontFamily={'Nunito Sans'}
                  fontSize={'1.15rem'}
                  lineHeight={'2rem'}>
                  {item.label}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
