enum RoutesEnum {
  HOME = '/',
  US = '/nosotros',
  POOLS = '/albercas',
  TRATAGUA = '/tratamientos-agua',
  PRODUCTS = '/productos',
  TESTIMONIALS = '/testimonios',
  CONTACT = '/contacto'
}
export default RoutesEnum;
